<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

      <div class="container">
        <div class="row mt-4 mb-4">
          <div class="col-md-12 text-center">
            <h3>Your Quote</h3>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="quote-blk p-3">
                <div class="mt-5">
                  <span style="color:#0077BE;font-size:1.3em;font-weight: bolder;">Your</span> <span style="color:#E42B1A;font-size:1.2em;font-weight: bolder;">Quote</span>
                </div>
                <div class="mb-5">
                  <span style="font-size:14px;">Congratulations! You have qualified for a discount of <span style="color:#E42B1A;font-weight: bolder"> KES.  {{(quotation.traditionalPremium - quotation.distancePremium) | currency('', 0)}} </span> </span>
                </div>
              </div>

              <div class="quote-blk p-3  mt-3">
                <div>
                  <span style="color:#0077BE;font-size:1.3em;font-weight: bolder;">Pay Now</span>
                </div>
                <div class="row mt-5">
                  <div class="col-md-6  mt-3">
                    <h3 style="color:#E42312;">-{{ discount | currency('', 0) }}%</h3>
                  </div>
                  <div class="col-md-6">
                    <span style="font-size:14px;" class="float-right traditional-price">Traditional Price: KES.  {{ quotation.traditionalPremium | currency('', 0) }}</span> <br/>
                    <span style="font-size:14px;" class="float-right low-mileage-price"><span style="color:#0077BE;">Low Mileage Price:</span> <span style="color:#E42B1A;"> KES.  {{ quotation.distancePremium | currency('', 0) }} </span></span> <br/>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-md-6">
              <div class="shadow-lg p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>Car Details</h4>
                </div>

                <table class="table">
                  <tr>
                    <th>Value of the car:</th>
                    <td class="float-right">KES. {{ quotation.sumInsured | currency('', 0) }}</td>
                  </tr>
                  <tr>
                    <th>Product:</th>
                    <td class="float-right">Motomatic</td>
                  </tr>
                  <tr>
                    <th>Cover Period:</th>
                    <td class="float-right">One Year</td>
                  </tr>
                  <tr>
                    <th>Make:</th>
                    <td class="float-right">{{ quotation.make }}</td>
                  </tr>
                  <tr>
                    <th>YoM:</th>
                    <td class="float-right">{{ quotation.yearOfManufacture }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-12">
              <h4>Cover Details</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div  class="quotation-details p-3" @click="showCoverDetails('details-about-your-cover')">
                <div class="row">
                  <div class="col-2">
                    <img style="max-height: 40px;" src="@/assets/images/details_about_cover.png" />
                  </div>
                  <div class="col-10">
                    <div class="quotation-details-primary">
                      Details about your cover
                    </div>
                    <div class="quotation-details-secondary">
                      Comprehensive cover runs for up to 10,000km
                    </div>
                  </div>
                </div>
              </div>

              <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('included-your-cover')">
                <div class="row">
                  <div class="col-2">
                    <img style="max-height: 40px;" src="@/assets/images/included_in_cover.png" />
                  </div>
                  <div class="col-10">
                    <div class="quotation-details-primary">
                      Included in your cover
                    </div>
                    <div class="quotation-details-secondary">
                      Comprehensive cover, courtesy cab, windscreen etc
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-6">
              <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('device-installation')">
                <div class="row">
                  <div class="col-2">
                    <img style="max-height: 40px;" src="@/assets/images/device_installations.png" />
                  </div>
                  <div class="col-10">
                    <div class="quotation-details-primary">
                      Device installation
                    </div>
                    <div class="quotation-details-secondary">
                      A Telematics Box must be installed at the start of the policy.
                    </div>
                  </div>
                </div>
              </div>

              <div  class="quotation-details p-3 mt-3" @click="showCoverDetails('key-exclusions')">
                <div class="row">
                  <div class="col-2">
                    <img style="max-height: 40px;" src="@/assets/images/exclusions.png" />
                  </div>
                  <div class="col-10">
                    <div class="quotation-details-primary">
                      Key exclusions & conditions
                    </div>
                    <div class="quotation-details-secondary">
                      Losses not covered
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="mt-3">
          <label for="termsAndConditions" >
            <input id="termsAndConditions" value="Installments" type="checkbox" v-model="termsAndConditions" /> &nbsp;&nbsp; <a target="_blank" href="https://motomatic.ke/terms-and-conditions" style="text-decoration: underline;color:#254E90;font-weight: bolder;" >Accept terms and conditions </a>
          </label>
        </div>

        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-6">
            <button @click="goBackToHomepage" class="btn btn-lg quote-btn  btn-outline-danger">  Buy Later</button>
          </div>
          <div class="col-md-6">
            <button @click="navigateToCoverWizard" style="background-color:#E42B1A;color:#fff;" class=" btn  btn-lg  quote-btn quotation-button">  Buy Now </button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>

    <modal height="400" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="details-about-your-cover" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Details about your cover</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('details-about-your-cover')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Cover runs for up to 10,000Kms after which you need to top-up mileage via mobile.</li>
            <li style="margin-bottom: 7px;">Failure to top-up kilometers on depletion immediately downgrades the cover to 3rd party until the renewal date.</li>
            <li style="margin-bottom: 7px;">Free Telematics Box must be installed in your vehicle at the start of your policy to track mileage, driving style and collision.</li>
            <li style="margin-bottom: 7px;">
              Telematics Box installation and valuation are done professionally and conveniently at a location of your choice.
            </li>
            <li style="margin-bottom: 7px;">
              Includes a Mobile App to track and top-up Kilometers as well as file and track claims progress.
            </li>
            <li style="margin-bottom: 7px;">
              Note: Failure to have the telematics device installed and valuation done within the first 7 days from inception of the policy will lead to an automatic downgrade of the policy from Comprehensive to third party.
            </li>
          </ul>
        </div>
      </div>
    </modal>


    <modal height="400" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="included-your-cover" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Included your cover</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('included-your-cover')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Accidental loss, damage or destruction to the vehicle(s) covered under the policy</li>
            <li style="margin-bottom: 7px;">Accidental loss or damage to other person’s property arising from usage of the covered vehicle.</li>
            <li style="margin-bottom: 7px;">Property of the participant and family is not covered.</li>
            <li style="margin-bottom: 7px;">
              Legal costs necessarily incurred pursuant to covered incidents involving the vehicle.
            </li>
            <li style="margin-bottom: 7px;">
              Accidental death or bodily injury to third parties in and outside the vehicle except the incident driver, the participant, employees of the participant and family members
            </li>
            <li style="margin-bottom: 7px;">
              Legal costs necessarily incurred pursuant to covered incidents involving the vehicle.
            </li>
            <li style="margin-bottom: 7px;">
              Courtesy Car during vehicle repair following accident, fire or theft of up to Kes. 30,000
            </li>
            <li style="margin-bottom: 7px;">
              Cost of replacing the windscreen in the event of an accident, or damage for up to Kes. 30,000
            </li>
            <li style="margin-bottom: 7px;">
              Your specified vehicle(s) is/are covered while parked, being services/repaired or in motion within the territorial limits shown on the policy schedule
            </li>
          </ul>
        </div>
      </div>
    </modal>


    <modal height="400" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="device-installation" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Device installation</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('device-installation')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">
              Device installation
              A Telematics Box must be installed in the vehicle at the start of the policy. The Box tracks the distance traveled throughout the policy (not exceeding 10,000km) and offers precise accident information for quicker claims processing.
            </li>

          </ul>
        </div>
      </div>
    </modal>


    <modal height="400" :resizable="resizable"
           :adaptive="adaptive"
           :draggable="draggable" name="key-exclusions" >
      <div class="cover-modal p-3">

        <div class="row">
          <div class="col-8">
            <h3>Key exclusions & conditions</h3>
          </div>
          <div class="col-4">
            <a style="border: none;background-color:#254e90 !important; color:#fff;" href="#" @click="hideCoverDetails('key-exclusions')" class="btn btn-sm btn-info float-right"> Close </a>
          </div>
        </div>


        <div class="cover-modal-details">
          <ul>
            <li style="margin-bottom: 7px;">Loss or injury sustained by the participant, family members, employees and driver at the time of the accident.</li>
            <li style="margin-bottom: 7px;">Deliberate or willful acts by the participant or employees leading to loss, damage or injury.</li>
            <li style="margin-bottom: 7px;">Acts of customs or other lawful or enforcement agencies carrying out official duty.</li>

          </ul>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        termsAndConditions: false,
        resizable: true,
        adaptive: true,
        draggable: true,
        isLoading: false,
        fullPage: true,
        quotation:{
          quoteId: "",
          modelId: "",
          traditionalPremium: "",
          sumInsured: "",
          make:"",
          model:"",
          yearOfManufacture:"",
          vehicleRegistrationNumber:"",
          distancePremium: ""
        },
        quote_summary:{
          cover_period:"One Year",

          items:[
            {
              name:"Promo Discount",
              value:0
            }
          ],

        },
        additional_benefits:[
                /*"Loss of Use",
                "Terrorism, sabotage & political risks",
                "Excess protector",
                "24 Hour Road Rescue Fees",
                "24 Hour Road Rescue (Infama)"*/
        ]
      };
    },
    computed:{
      total(){
        return this.quote_summary.items.reduce((sum, item) => sum + item.value, 0)
      },
      discount(){
        return ((this.quotation.traditionalPremium - this.quotation.distancePremium ) / this.quotation.traditionalPremium ) * 100;
      }
    },
    mounted(){

      //this.quotation = JSON.parse(localStorage.getItem("quote"));
      this.getQuoteData();
    },
    components: {
      Loading
    },

    methods:{
      ...mapActions(["getQuoteDetails"]),
      hideCoverDetails(modalName){
        this.$modal.hide(modalName);
      },
      showCoverDetails(modalName){
        this.$modal.show(modalName);
      },

      getQuoteData(){
        let self = this;
        this.isLoading = true;

        this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
          self.isLoading = false;

          self.quotation.quoteId = quote._id;
          self.quotation.modelId = quote.modelId;
          self.quotation.traditionalPremium = quote.traditionalPremium;
          self.quotation.sumInsured = quote.sumInsured;
          self.quotation.make = self.$route.query.make;
          self.quotation.yearOfManufacture = quote.yearOfManufacture;
          self.quotation.vehicleRegistrationNumber = quote.vehicleRegistrationNumber;
          self.quotation.distancePremium = quote.distancePremium;

          self.quote_summary.items.splice(0,0 ,{
            name:"Gross Premium",
            value: self.quotation.traditionalPremium
          })

          self.quote_summary.items.splice(self.quote_summary.items.length - 1,0 ,{
            name:"Personalized Discount",
            value: self.quotation.traditionalPremium - self.quotation.distancePremium
          });

          console.log(self.quotation);

          localStorage.setItem("quote",JSON.stringify(
                  {
                    quoteId: quote._id,
                    modelId: quote.modelId,
                    traditionalPremium: quote.traditionalPremium,
                    sumInsured: quote.sumInsured,
                    make:self.$route.query.make,
                    yearOfManufacture:quote.yearOfManufacture,
                    vehicleRegistrationNumber:quote.vehicleRegistrationNumber,
                    distancePremium: quote.distancePremium
                  }
          ));


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },

      goBackToHomepage(){
        location.replace("https://motomatic.ke");
      },
      navigateToCoverWizard(){

        if(this.termsAndConditions){
          this.$router.push('/checkout')
        }
        else{
          this.$toast.open({
            message: "Agree to terms and conditions to proceed",
            type: 'warning',
            duration:5000,
            position:"top-right"
          });
        }
      }
    }
  }
</script>

<style>

.table th, .table td{
  padding: 10px !important;
}

  .list-group-item {
    background-color: transparent !important;
    color:#fff !important;
    border:none !important;
  }

  .quote-summary{
    padding: 20px;
    background-color: #2F3641;
    border-radius: 5px;
    color:#fff;
  }

  .quote-summary h4 {
    font-size: 1.2em;
  }

  .quote-summary h4, .quote-summary h5,  .quote-success h4{
    color:#fff;
  }

  .quote-success{
    background-color:#13233A;
    border-radius: 5px;
    padding: 40px 20px;
    color:#fff;
  }

  .quote-btn{
    width: 100%;
  }


</style>
